import { currentEnvConfig } from '@/config'
import type { ITemplateList } from '@/interface/template'
import type { IUserInfo } from '@/interface/user'
import request from '@/utils/request'

// 获取当前用户信息
export function getCurrentUserInfo() {
  return request<IUserInfo>({
    url: `/api/user/getCurrentUserInfo`,
    baseURL: currentEnvConfig.rewriteBaseURL
  })
}

// 机器人模板列表
export function getTemplateList() {
  return request<ITemplateList[]>({
    method: 'get',
    url: `/chato/api/v2/domains/template/list`
  })
}

// 创建机器人
export function createDomain(id) {
  return request({
    method: 'post',
    url: `/chato/api/v1/domains/create_by_template?template_id=${id}`
  })
}

// 创建机器人
export function createDomainV2(id) {
  return request({
    method: 'post',
    url: `/chato/api/v2/domains/create_by_template?template_id=${id}`
  })
}

// 创建机器人 - By AI
export function createDomainByAI(data) {
  return request({
    method: 'post',
    url: `chato/api/v2/domains/create_by_ai`,
    data
  })
}

// 更新当前用户的信息
export function updateUser(data) {
  return request({
    method: 'put',
    baseURL: currentEnvConfig.rewriteBaseURL,
    url: `/api/user/update`,
    data
  })
}
