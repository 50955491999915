import { currentEnvConfig } from '@/config'
import { EMaxHeight } from '@/enum/chat'
import { RoutesMap } from '@/router'
import { useTodoStore } from '@/stores/todo'
import { chatoIframe } from '@/utils/iframe'
import { defineStore, storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export const useContactUS = defineStore('useContactUS', () => {
  const triggerEl = ref()
  const contactUSVisible = ref(false)
  const onAdvisory = () => {
    onContactUs()
  }
  const route = useRoute()
  let contactTimer = null

  // 点击联系我们的按钮，可通过参数控制显示或隐藏
  const onContactUs = (display = 'block') => {
    const containerEl = document.getElementById('inframe_container')
    if (containerEl) {
      // 若iframe容器DOM存在,则显示它
      containerEl.style.maxHeight = EMaxHeight[display]
    }
    // nextTick(() => {
    const { todoVisible } = storeToRefs(useTodoStore())
    if (display === 'block') {
      contactUSVisible.value = true
      // 若显示了iframe对话框,则隐藏todoList
      // todoVisible.value = false
    } else {
      contactUSVisible.value = false
      // todoVisible.value = true
    }
    if (route.name === RoutesMap.tranning.botChat) {
      transformContactUS('translateY(-70px)')
    } else {
      transformContactUS('translateY(0)')
    }
    // })
  }
  // 往页面中注册添加iframe的方法
  const onRegisterContactUS = () => {
    // 首页右下角Antopia的官方聊天机器人配置（静态的）
    window.ChatoBotConfig = {
      baseURL: 'https://api.chato.cn',
      // wwwBaseURL: 'https://chato.cn',
      wwwBaseURL: currentEnvConfig.wwwBaseURL,
      token: 'kv9ez5y8qxp7l3m4',
      id: 1
    }
    const containerEl = document.getElementById('inframe_container')
    if (!containerEl) {
      // 若没有iframe容器DOM,则添加一个
      chatoIframe()
      if (route.name !== RoutesMap.tranning.botChat) {
        // 若不是体验中心，则5秒后自动显示Antopia的聊天框
        contactTimer = setTimeout(() => {
          onContactUs()
        }, 5000)
      }
    }
  }
  const transformContactUS = (transformString) => {
    const containerEl = document.getElementById('inframe_container')
    containerEl && (containerEl.style.transform = transformString)
  }
  watch(
    () => triggerEl.value,
    (n, o) => {
      const { todoVisible } = storeToRefs(useTodoStore())
      if (route.name !== RoutesMap.tranning.botChat) {
        todoVisible.value = !contactUSVisible.value
      } else {
        if (o) {
          console.log(o)
          console.log(n)

          todoVisible.value = !contactUSVisible.value
        }
      }
    }
  )
  // watch(
  //   () => contactUSVisible.value,
  //   (n, o) => {
  //     const { todoVisible } = storeToRefs(useTodoStore())
  //     todoVisible.value = !contactUSVisible.value
  //   }
  // )
  return {
    triggerEl,
    contactUSVisible,
    onRegisterContactUS,
    transformContactUS,
    onContactUs,
    onAdvisory
  }
})
