import request from '@/utils/request'
import { currentEnvConfig } from '@/config'

// 获取当前用户所有的待办列表
export function getCheckList() {
  return request({
    method: 'get',
    baseURL: currentEnvConfig.rewriteBaseURL,
    url: `/api/checkList/getList`
  })
}

// 更新当前用户对应待办id的待办信息
export function updateCheck(data) {
  return request({
    method: 'put',
    baseURL: currentEnvConfig.rewriteBaseURL,
    url: `/api/checkList/update/${data.id}`,
    data
  })
}
