import { useContactUS } from '@/stores/contactUS'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { getCheckList, updateCheck } from '@/api/checkList'

export const useTodoStore = defineStore('todo', () => {
  const { onContactUs } = useContactUS()
  const todoVisible = ref(true)
  const todoList = ref([])
  const currentTodoName = ref('')
  const total = computed(() => {
    return todoList.value?.length || 0
  })
  const doneTotal = computed(() => {
    return todoList.value?.filter((item) => item.complete).length || 0
  })
  const percent = computed(() => {
    return `${((doneTotal.value / total.value) * 100).toFixed(2)}%`
  })
  const fetchTodo = async () => {
    try {
      const data = await getCheckList()
      todoList.value = data
    } catch (error) {
      console.error(error)
    }
  }
  const updateTodo = async (todo) => {
    try {
      await updateCheck(todo)
    } catch (error) {
      console.error(error)
    }
  }
  const toggleTodoVisible = () => {
    todoVisible.value = !todoVisible.value
    if (todoVisible.value) {
      // 若显示了todoList，则隐藏iframe对话框
      onContactUs('none')
    }
  }
  return {
    currentTodoName,
    todoVisible,
    todoList,
    total,
    doneTotal,
    percent,
    fetchTodo,
    updateTodo,
    toggleTodoVisible
  }
})
