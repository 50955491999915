import { defineStore } from 'pinia'

export const sidebarStore = defineStore('sidebar', {
  state: () => {
    return {
      drawerVisible: false, // 导航抽屉 visible
      isCollapse: true // 左侧菜单默认折叠
    }
  },
  actions: {
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    }
  }
})
