import { currentEnvConfig } from '@/config'
export default {
  install(app, options) {
    const loadBaiduAnalytics = (hmId) => {
      const hm = document.createElement('script')
      hm.src = `https://hm.baidu.com/hm.js?${hmId}`
      const s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    }
    if (currentEnvConfig.env === 'production') {
      loadBaiduAnalytics(options.hmId)
    }
  }
}
