import { currentEnvConfig } from '@/config'
import { EFengkongField } from '@/enum/common'
import { ELangKey } from '@/enum/locales'
import type { IResponse } from '@/interface/common'
import i18n from '@/locales'
import router, { RoutesMap } from '@/router'
import { useAuthStore } from '@/stores/auth'
import { useLocalStorage } from '@vueuse/core'
import axios, { AxiosError, type AxiosResponse, type InternalAxiosRequestConfig } from 'axios'
import { handleRequestError } from './help'
let tokenAbnormal = false

const $t = i18n.global.t

const currentlocales = useLocalStorage('locale', ELangKey.zh_cn)

const baseURL = currentEnvConfig.baseURL

const service = axios.create({
  baseURL,
  timeout: 90_000 // 请求超时时间 (ms)
})

type AxiosRequestConfigType<D = any> = InternalAxiosRequestConfig<D> & { enforceAnonymity: boolean }

// 请求拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfigType) => {
    const { headers, enforceAnonymity } = config
    const authStoreI = useAuthStore()
    const token = authStoreI.authToken
    headers['X-LANG'] = currentlocales.value
    if (token && !enforceAnonymity) headers.Authorization = 'Bearer ' + token
    return config
  },
  (error) => {
    handleRequestError(error, $t('网络异常，请检查后重试'))
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    // 服务器返回2XX状态码触发
    const {
      data: { code, data, message }
    } = response
    if (code !== 200) {
      handleRequestError(null, $t(message))
      switch (code) {
        // 错误信息已显示，仅处理页面动作
        case 401: {
          tokenAbnormal = true
          if (window.location.pathname !== '/') {
            router.replace('/auth/login')
          }
          break
        }
        case 200011: {
          const errorMap = data
            .reduce((pre: string, cur: EFengkongField) => {
              return pre + EFengkongField[cur] + '、'
            }, '')
            .slice(0, -1)
          handleRequestError(null, $t(`${errorMap}${message}，请重新操作`))
          break
        }
        case 210401: {
          router.push({
            name: RoutesMap.auth.loginInvite,
            query: {
              pay: '1',
              slug: data.data.domain_slug
            }
          })
        }
      }
      if (!tokenAbnormal) {
        tokenAbnormal = true
        handleRequestError(null, $t(message))
        const timer = setTimeout(() => {
          tokenAbnormal = false
          clearTimeout(timer)
        }, 3000)
      }
    }

    return data
  },
  (error: AxiosError) => {
    // 服务器返回非2XX状态码触发
    const { response, request } = error
    const {
      response: {
        data: { message }
      }
    } = error
    // 请求成功发出，响应成功拿到，但是状态非2XX
    if (response) {
      if (response.status === 401) {
        const authStoreI = useAuthStore()
        authStoreI.logout()
        if (window.location.pathname !== '/') {
          // 处理401无权限错误
          tokenAbnormal = true
          // handleRequestError(error, $t('您的登录状态已失效，请重新登录。'))
          router.push('/auth/login')
        }
        return
      } else if (response.status === 500) {
        handleRequestError(error)
      }
    } else if (request) {
      // 对请求未成功发送到服务器的错误进行处理，例如网络错误
      handleRequestError(error, $t('网络异常，请检查后重试'))
      return Promise.reject(error)
    }
    // Todo: 避免3s内客户端显示很多报错
    if (!tokenAbnormal) {
      tokenAbnormal = true
      // 统一处理其他错误
      handleRequestError(error, $t(message))
      const timer = setTimeout(() => {
        tokenAbnormal = false
        clearTimeout(timer)
      }, 3000)
    }
    return Promise.reject(error)
  }
)

/**
 * 核心函数，可通过它处理一切请求数据，并做横向扩展
 */
function request<T = any>(options) {
  const method = options.method || 'get'
  // get 请求做参数的兼容
  if (method.toLowerCase() === 'get') {
    if (!options.params) options.params = options.data
  }
  return service<IResponse<T>>(options)
}

export default request
