import { getTestConfig } from '@/api/abtest'
import { getCurrentUserInfo, updateUser } from '@/api/user'
import useLocationDvid from '@/composables/useLocationDvid'
import { ESpaceCommercialType } from '@/enum/space'
import type { IUserInfo } from '@/interface/user'
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { handleClientError } from '@/utils/help'

interface State {
  authToken: any
  userInfo: Partial<IUserInfo> // 当前空间用户
  collectConfig: any
  userCommercialType: ESpaceCommercialType
  abTestConfig: Record<string, string>
}

interface UpdateUserInfoAttri {
  avatar: string
  nickname: string
}

export const useBase = defineStore('base', {
  state: (): State => {
    return {
      authToken: '',
      userInfo: {},
      collectConfig: {},
      userCommercialType: ESpaceCommercialType.free,
      abTestConfig: {}
    }
  },
  actions: {
    getAuthToken() {
      this.authToken = useStorage<string>('auth_token', '')
      return Promise.resolve(this.authToken)
    },
    async getUserInfo() {
      try {
        const data = await getCurrentUserInfo()
        this.userInfo = data
        this.userCommercialType = ESpaceCommercialType.free
        return Promise.resolve(this.userInfo)
      } catch (error) {
        handleClientError(error)
      }
    },
    // 扣除用户信息的电力值
    async decreaseUserCredit(decreaseNum: number) {
      try {
        const data = await updateUser({
          credit: this.userInfo.credit - decreaseNum
        })
        this.userInfo = data
      } catch (error) {
        console.error(error)
      }
    },
    // 更新当前用户信息
    updateUserInfoAttri(info: UpdateUserInfoAttri) {
      this.userInfo = {
        ...this.userInfo,
        ...info
      }
    },
    async getABTestConfig() {
      try {
        const { dvid } = useLocationDvid()
        const data = await getTestConfig(dvid)
        const config = data
        this.abTestConfig = config
        return Promise.resolve(config)
      } catch (error) {
        console.error(error)
      }
    }
  }
})
