import { sidebarStore } from '@/stores/sidebar'
import { storeToRefs } from 'pinia'

export default function useSidebar() {
  const sideBarStoreI = sidebarStore()
  const { drawerVisible, isCollapse } = storeToRefs(sideBarStoreI)
  const { toggleCollapse } = sideBarStoreI

  return { drawerVisible, isCollapse, toggleCollapse }
}
